<script setup>

import { createPinia } from "pinia";
import { usePreferencesStore } from "../stores/preferencesStore";

const pinia = createPinia();
const preferences = usePreferencesStore(pinia);

function toggleDarkMode() {
    preferences.actions.toggleDarkMode();
}
</script>

<template>
    <v-btn @click="toggleDarkMode" variant="tonal" class="mr-4 tw-w-10 tw-h-10">
        <v-icon >{{ preferences.state.darkMode ? 'mdi-white-balance-sunny' : 'mdi-weather-night' }}</v-icon>
    </v-btn>
</template>

<style scoped>

</style>
